<template>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="adminTableClassHeaders"
      :items="orders"
      :search="$store.state.search"
      
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link :to="{ name: 'Class', params: { id: item.id } }">{{
          item.id || "--"
        }}</router-link>
      </template>
  
      
  
      
      <template v-slot:[`item.client`]="{ item }">
        <router-link
          v-if="item.client"
          :to="{ name: 'ClientProfile', params: { id: item.client_id } }"
          >{{ item.client|| "--" }}</router-link
        >
      </template>

      <template v-slot:[`item.cost`]="{ item }">
       <span>$ {{ item.cost }}</span>
      </template>
  
      
  
      
  
      <template v-slot:[`item.status`]="{ item }">
        <span
          style="min-width: 50px !important"
          v-html="item.status"
        >
          
        </span>
      </template>
  
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          elevation="0"
          :to="{ name: 'Class', params: { id: item.id } }"
          fab
          x-small
        >
        <v-icon scale="1.3" name="eye" color="black" />
  
        </v-btn>
      </template>
    </v-data-table>
  </template>
  
  
  <script>
  import { mapState } from "vuex";
  export default {
    props: {
      orders: {
        type: Array,
      },
      user: {
        type: Object,
      },
      api_paginated: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapState("orders", ["adminTableClassHeaders"]),
      // ...mapState("auth", ["user"]),
  
    },
    
  };
  </script>