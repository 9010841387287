<template>
    <div class="mb-3 " >
      <BaseLoading
        style="min-height: 400px; height: 90vh"
        v-if="loading"
      ></BaseLoading>
  
      <div class="mb-3 " v-else>
        <BaseHeader :title="`${$route.params.status} Classes`" ></BaseHeader>
  
        <div class=" mb-3 border-0 ">
          <!-- table headers  -->
          
          <div class=" card d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1" style>
            <h2 class="card-title w-50 font-weight-black text-capitalize">
            
          </h2>
            <v-text-field
            v-model="$store.state.search"
              dense
              outlined
              label="Filter classes"
              name="url"
              type="text"
              hide-details
            />
          </div>
          <!-- table headers  -->
  
          <!-- // admin -->
          <admin-table-class
            v-if="   userType == userTypes.admin "
            :orders="classHelp"
            :api_paginated="true"
          ></admin-table-class>

          <!-- {{ classHelp }} -->
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import AdminTable from "../../components/tables/AdminTable.vue";
  import AdminTableClass from "../../components/tables/AdminTableClass.vue";

  // import ClientTable from "../../components/tables/ClientTable.vue";
  import WriterTable from "../../components/tables/WriterTable.vue";
  import EditorTable from "../../components/tables/EditorTable.vue";
  import SubadminTable from "../../components/tables/SubadminTable.vue";
  
  export default {
    name: "OrderListing",
    computed: {
      ...mapState("orders", ["orders", "filters", "classes", "classHelp", "filters"]),
      ...mapGetters("auth", ["userType", "userTypes"]),
      ...mapState("auth", ["user"])
  
    },
  
    components: {
      AdminTable,
      // ClientTable,
      AdminTableClass,
      WriterTable,
      EditorTable,
      SubadminTable,
  
    },
  
    data() {
      return {
        loading: false,
      };
    },
  
    beforeRouteUpdate(to, from, next) {
      this.loading = true;
      let payload = to.params && to.params.status ? to.params.status : "editing";
       this._getClassesStatus(payload)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
      next();
    },
  
    methods: {
      ...mapActions("orders", ["_getOrders", "_navigate"]),
      ...mapActions("sidebar", ["getSidebarMenu"]),
    //   ...mapActions("orders", ["_getClassHelpStats"]),
      ...mapActions("orders", ["_getClassesStatus", "_getClasses", "_navigate"]),

      navigate(route) {
        this.loading = true;
        this._navigate(route)
          .catch((err) => console.log(err))
          .finally(() => (this.loading = false));
      },
    },
  
   async mounted() {
      this.loading = true;
      // console.log(this.$route.params.status);
     await this.getSidebarMenu();
    //  await this._getClasses()
     await this._getClassesStatus(this.$route.params.status || "editing")
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
      // this._getOrders(this.$route.params.status || "editing")
      //   .catch((err) => console.log(err))
      //   .finally(() => (this.loading = false));
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .is-title {
    max-height: 3px !important;
    overflow-y: hidden;
    line-height: 1.5;
    overflow: hidden;
  }
  </style>
  