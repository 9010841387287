<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="writerTableHeaders"
      :items="orders"
      :search="$store.state.search"
      :hide-default-footer="true"
      :disable-pagination="true"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link :to="{ name: 'Order', params: { id: item.id } }">{{
          item.id || ""
        }}</router-link>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <router-link :to="{ name: 'Order', params: { id: item.id } }">
          <span v-html="item.title"></span>
        </router-link>
      </template>

      <template v-slot:[`item.writer_cost`]="{ item }">
        {{ item.writer_cost ? `$${item.writer_cost}` : "" }}
      </template>

      <template v-slot:[`item.writer_deadline`]="{ item }">
        <span
          style="min-width: 50px !important"
          :class="
            item.writer_deadline.past_due ? 'text-danger' : 'text-success'
          "
        >
          {{ item.writer_deadline.date || "" }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn-toggle>
          <v-btn
            color="primary"
            small
            :to="{ name: 'Order', params: { id: item.id } }"
            >View</v-btn
          >
          <v-btn
            v-if="is_available"
            color="primary"
            small
            @click="
              $store.state.dialog = true;
              activeOrder = item;
            "
            >Bid</v-btn
          >
        </v-btn-toggle>
      </template>
    </v-data-table>

    <!-- bid -->
    <v-dialog
      v-model="$store.state.dialog"
      max-width="290"
      class="text-left"
      transition="dialog-top-transition"
    >
      <v-card :loading="bidsForm.busy">
        <v-card-title class="text-h5 text-capitalize">
          Bid order (#{{ activeOrder.id || "" }})
        </v-card-title>

        <v-card-text class="text-left">
          Are you sure you want to bid
          <span class="text-weight-bold">{{ activeOrder.title || "" }} </span>
        </v-card-text>

        <v-card-actions>
          <button
            color="error darken-1"
            text
            @click="$store.state.dialog = false"
          >
            Cancel
          </button>

          <v-btn
            color="green darken-1"
            text
            @click="bid"
            :disabled="bidsForm.busy"
          >
            Yes, Bid
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- bid -->
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    orders: {
      type: Array,
    },
  },
  data() {
    return {
      activeOrder: {},
    };
  },
  computed: {
    ...mapState("orders", ["writerTableHeaders", "bidsForm"]),
    is_available() {
      let is_available = false;
      if (this.$route.params.canbid === "biding") {
        is_available = true;
      }
      return is_available;
    },
  },

  methods: {
    ...mapActions("orders", ["bidOrder", "_getOrders"]),
    bid() {
      this.bidsForm.order_id = this.activeOrder.id;
      this.bidOrder(this.bidsForm)
        .then((res) => {
          this.$store.state.dialog = false;
          this._getOrders(this.$route.params.status || "editing");
          this.$swal({
                icon: 'success',
                title: 'Success',
                text: (res.data && res.data.Message) || "Order bid successfully.",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
        })
        .catch((err) => {
          this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data ? err.response.data.Message : "Error while bidding order",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
        });
    },
  },

  mounted() {},
};
</script>